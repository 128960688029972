<template>
  <v-dialog v-model="open" width="700" max-width="100%" persistent>
    <v-card
      class="mx-auto"
      :class="{
        'w-vinci-form-modal': true,
        'w-vinci-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        :src="
          $voicer.isMobile
            ? require('~/assets/images/Popupmobile.jpg')
            : require('~/assets/images/Popupdesktop.jpg')
        "
        alt="image popup"
        :max-height="$voicer.isMobile ? 368 : 350"
        @click="handleClick"
      />
      <!-- <strong
        ><div v-if="isFRVisible || isBEVisible" class="text-alternate pa-2 pt-2">
          On a besoin de vous !
        </div></strong
      > -->
      <strong
        ><div v-if="isPLVisible" class="text-alternate pa-2 pt-2">
          Potrzebujemy Cię!
        </div></strong
      >
      <strong
        ><div v-if="isPTVisible" class="text-alternate pa-2 pt-2">
          Precisamos de você!
        </div></strong
      >
      <!-- <div v-if="isFRVisible || isBEVisible" class="text-alternate pa-1">
        Totem Mousquetaires : Quels sont les atouts des Mousquetaires ?
        Qu’est-ce qui nous rassemble ? Merci de prendre 5 minutes pour répondre
        à ce questionnaire
      </div> -->
      <div v-if="isPLVisible" class="text-alternate pa-1">
        Totem muszkieterów: Jakie są mocne strony muszkieterów? Co nas łączy?
        Prosimy o poświęcenie 5 minut na wypełnienie kwestionariusza
      </div>
      <div v-if="isPTVisible" class="text-alternate pa-1">
        Totem dos Mosqueteiros: Quais são os pontos fortes dos Mosqueteiros? O
        que nos une? Por favor, reserve 5 minutos para responder a este
        questionário
      </div>
      <div class="text-alternate pa-1">
        <!-- <v-btn
          v-if="isFRVisible || isBEVisible"
          ref="btn"
          color="primary"
          depressed
          class="ma-2 white--text"
          @click.stop="
            handleOpen(
              'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZUNE1TSjhPUDVPTFI4MlRITEQ3RkhXVU1ZMi4u&route=shorturl'
            )
          "
        >
          <v-icon color="#ffffff" class="mr-2">mdi-form-select</v-icon>
          JE RÉPONDS
        </v-btn> -->
        <v-btn
          v-if="isPLVisible"
          ref="btn"
          color="primary"
          depressed
          class="ma-2 white--text"
          @click.stop="
            handleOpen(
              'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZURTUxOUpVTlNJNEVaR0FZMEczME1RNlNYQS4u&route=shorturl'
            )
          "
        >
          <v-icon color="#ffffff" class="mr-2">mdi-form-select</v-icon>
          ODPOWIEM
        </v-btn>
        <v-btn
          v-if="isPTVisible"
          ref="btn"
          color="primary"
          depressed
          class="ma-2 white--text"
          @click.stop="
            handleOpen(
              'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZURERIVzZKQkwyOVE2TzNWTlFYMkVJRTJKSS4u&route=shorturl'
            )
          "
        >
          <v-icon color="#ffffff" class="mr-2">mdi-form-select</v-icon>
          EU RESPONDO
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open')
    let open = false

    if (!lastOpen && (!this.isFRVisible || !this.isBEVisible)) {
      open = true
    } else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
      isFRVisible: false,
      isBEVisible: false,
      isPLVisible: false,
      isPTVisible: false,
      isSTVisible: false,
      // Name the segment to check (case sensitive)
      segmentFR: 'pays:france',
      segmentBE: 'pays:belgique',
      segmentPL: 'pays:pologne',
      segmentPT: 'pays:portugal',
      seg: ['status:adherent', 'status:permanentsrtg', 'status:mediameeting'],
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  mounted() {
    for (
      let index = 0;
      index < this.$spoke.user.data.protected.segments.length;
      index++
    ) {
      if (
        this.$spoke.user.data.protected.segments[index].name === this.segmentFR
      ) {
        this.isFRVisible = true
        // désactivation temporaire segment fr
        this.open = false
      } else if (
        this.$spoke.user.data.protected.segments[index].name === this.segmentBE
      ) {
        this.isBEVisible = true
        // désactivation temporaire segment be
        this.open = false
      } else if (
        this.$spoke.user.data.protected.segments[index].name === this.segmentPL
      ) {
        this.isPLVisible = true
      } else if (
        this.$spoke.user.data.protected.segments[index].name === this.segmentPT
      ) {
        this.isPTVisible = true
      } else if (
        this.seg.includes(this.$spoke.user.data.protected.segments[index].name)
      ) {
        this.isSTVisible = true
      }
    }
    if (!this.isSTVisible) {
      this.open = false
    }
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="scss">
.headline {
  font-size: 15px;
}
.w-vinci-form-modal {
  &--mobile {
    display: flex;
    flex-direction: column;
    .v-card__text {
      flex-grow: 1;
      position: relative;
    }
  }
  &__body2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .body-2 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .body-1 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .text-alternate {
    color: #313335;
    text-align: center;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[_c('exc-dialog'),_vm._v(" "),_c('v-container',{staticClass:"pa-0"},[(_vm.isSTVisible)?_c('v-sheet',{ref:"sheet",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"accent"}},[_c('div',{staticClass:"pa-3",class:{
          'body-2 font-weight': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[(_vm.isFRVisible || _vm.isBEVisible)?_c('div',{staticClass:"pl-2 pr-2 pt-1 pb-2"},[_vm._v("\n          Les valeurs Mousquetaires : c'est quoi pour vous ? Merci de prendre\n          5 minutes pour répondre à ce questionnaire\n        ")]):_vm._e(),_vm._v(" "),(_vm.isPLVisible)?_c('div',{staticClass:"pl-2 pr-2 pt-1 pb-2"},[_vm._v("\n          Wartości Muszkietera: czym są dla Ciebie? Prosimy o poświęcenie 5\n          minut na udzielenie odpowiedzi na tę ankietę\n        ")]):_vm._e(),_vm._v(" "),(_vm.isPTVisible)?_c('div',{staticClass:"pl-2 pr-2 pt-1 pb-2"},[_vm._v("\n          Os valores do Mosqueteiro: o que são para você? Por favor, reserve 5\n          minutos para responder a este questionário\n        ")]):_vm._e(),_vm._v(" "),(_vm.isFRVisible || _vm.isBEVisible)?_c('v-btn',{ref:"btn",staticClass:"ma-2",attrs:{"color":"primary","rounded":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpen(
              'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZUNE1TSjhPUDVPTFI4MlRITEQ3RkhXVU1ZMi4u&route=shorturl'
            )}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffff"}},[_vm._v(" mdi-form-select ")]),_vm._v("\n          JE RÉPONDS\n        ")],1):_vm._e(),_vm._v(" "),(_vm.isPLVisible)?_c('v-btn',{ref:"btn",staticClass:"ma-2",attrs:{"color":"primary","rounded":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpen(
              'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZURTUxOUpVTlNJNEVaR0FZMEczME1RNlNYQS4u&route=shorturl'
            )}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffff"}},[_vm._v(" mdi-form-select ")]),_vm._v("\n          ODPOWIEM\n        ")],1):_vm._e(),_vm._v(" "),(_vm.isPTVisible)?_c('v-btn',{ref:"btn",staticClass:"ma-2",attrs:{"color":"primary","rounded":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpen(
              'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZURERIVzZKQkwyOVE2TzNWTlFYMkVJRTJKSS4u&route=shorturl'
            )}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffff"}},[_vm._v(" mdi-form-select ")]),_vm._v("\n          EU RESPONDO\n        ")],1):_vm._e()],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
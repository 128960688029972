/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
  ...require('./config.common')({
    agromousquetaires: '65ce2689156b45473ab835d5',
    RTA: '600fe2533d4667c3906e1e95',
    RTAFilterTag: '60dc68afb3541397942d8f3a',
    RTG: '600fe2663d466740f66e1e97',
    RTM: '600fe25d3d46671ff26e1e96',
    RTMFilterTag: '60dc68afb3541397942d8f3a',
  }),
}

<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <exc-dialog />
    <v-container class="pa-0">
      <v-sheet
        v-if="isSTVisible"
        ref="sheet"
        color="accent"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="pa-3"
          :class="{
            'body-2 font-weight': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div v-if="isFRVisible || isBEVisible" class="pl-2 pr-2 pt-1 pb-2">
            Les valeurs Mousquetaires : c'est quoi pour vous ? Merci de prendre
            5 minutes pour répondre à ce questionnaire
          </div>
          <div v-if="isPLVisible" class="pl-2 pr-2 pt-1 pb-2">
            Wartości Muszkietera: czym są dla Ciebie? Prosimy o poświęcenie 5
            minut na udzielenie odpowiedzi na tę ankietę
          </div>
          <div v-if="isPTVisible" class="pl-2 pr-2 pt-1 pb-2">
            Os valores do Mosqueteiro: o que são para você? Por favor, reserve 5
            minutos para responder a este questionário
          </div>
          <v-btn
            v-if="isFRVisible || isBEVisible"
            ref="btn"
            color="primary"
            rounded
            depressed
            class="ma-2"
            @click.stop="
              handleOpen(
                'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZUNE1TSjhPUDVPTFI4MlRITEQ3RkhXVU1ZMi4u&route=shorturl'
              )
            "
          >
            <v-icon color="#ffff" class="mr-2"> mdi-form-select </v-icon>
            JE RÉPONDS
          </v-btn>
          <v-btn
            v-if="isPLVisible"
            ref="btn"
            color="primary"
            rounded
            depressed
            class="ma-2"
            @click.stop="
              handleOpen(
                'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZURTUxOUpVTlNJNEVaR0FZMEczME1RNlNYQS4u&route=shorturl'
              )
            "
          >
            <v-icon color="#ffff" class="mr-2"> mdi-form-select </v-icon>
            ODPOWIEM
          </v-btn>
          <v-btn
            v-if="isPTVisible"
            ref="btn"
            color="primary"
            rounded
            depressed
            class="ma-2"
            @click.stop="
              handleOpen(
                'https://forms.office.com/pages/responsepage.aspx?id=VVcUmoRs1UW3_NP-Kkjfqg77U0dBroJHrUe3r6wbSDZURERIVzZKQkwyOVE2TzNWTlFYMkVJRTJKSS4u&route=shorturl'
              )
            "
          >
            <v-icon color="#ffff" class="mr-2"> mdi-form-select </v-icon>
            EU RESPONDO
          </v-btn>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExcDialog from '~/excentrics/components/ExcDialog'

export default {
  components: {
    ExcDialog,
  },
  data() {
    return {
      isFRVisible: false,
      isBEVisible: false,
      isPLVisible: false,
      isPTVisible: false,
      isSTVisible: false,

      // Name the segment to check (case sensitive)
      segmentFR: 'pays:france',
      segmentBE: 'pays:belgique',
      segmentPL: 'pays:pologne',
      segmentPT: 'pays:portugal',
      seg: ['status:adherent', 'status:permanentsrtg', 'status:mediameeting'],
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
  mounted() {
    for (
      let index = 0;
      index < this.$spoke.user.data.protected.segments.length;
      index++
    ) {
      if (
        this.$spoke.user.data.protected.segments[index].name === this.segmentFR
      ) {
        this.isFRVisible = true
      } else if (
        this.$spoke.user.data.protected.segments[index].name === this.segmentBE
      ) {
        this.isBEVisible = true
      } else if (
        this.$spoke.user.data.protected.segments[index].name === this.segmentPL
      ) {
        this.isPLVisible = true
      } else if (
        this.$spoke.user.data.protected.segments[index].name === this.segmentPT
      ) {
        this.isPTVisible = true
      } else if (
        this.seg.includes(this.$spoke.user.data.protected.segments[index].name)
      ) {
        this.isSTVisible = true
      }
    }
  },

  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.message_welcome_sir {
  border-radius: 6px !important;
}
.v-sheet__desktop {
  & > div {
    align-items: center;
    font-family: rubik;
    font-size: 16px !important;
  }
}
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-size: 16px;
}
.v-btn {
  border-radius: 4px;
  align-items: center;
}
.v-sheet {
  border-radius: 8px;
  justify-content: center;
}
</style>

<template>
  <div class="w-block__footer">
    <!-- footer block -->
    <div class="mt-1 mb-4">
      <div>
        Des remarques ? Des suggestions ? Des sujets à aborder ?
        <div>
          Contactez-notre hotline au
          <a href="tel:+33186868606">01 86 86 86 06</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

const $get = require('lodash.get')
const $clone = require('lodash.clone')

// RTG, un user verra la collection si il possède:
const USER_RTG_COLLECTION = [
  'status:associe',
  'status:adherent',
  'status:perseverant',
  'status:postulantaffectable',
  'status:postulantaffecte',
  'status:permanentsrtg',
  'status:mediameeting',
]

// RTA, un user verra la collection si il possède:
const USER_RTA_COLLECTION = ['metier:alimentaire']

// RTM, un user verra la collection si il possède:
const USER_RTM_COLLECTION = ['metier:maison']

const findTag = (slug, tags) => {
  return tags.find((tag) => tag.slug === slug)
}

module.exports = (tagIDs) => ({
  siteId: '5fb26471b57d98991071a950',
  metadatas: {
    name: 'Les Ondes M',
    colorPrimary: '#e2241c',
    colorSuccess: '#18ba20',
    supportEmailAddress: 'contact@les-ondes-m.com',
  },
  authMode: ['local'],
  authLocalOptions: {
    mode: 'otp',
  },
  analytics: {
    matomo: '30',
  },
  authLocaleLoginFormat: ['phone'],
  enableExperimentalBlocks: true,
  enableSplashScreen: 'wavy',
  disabledComponents: [
    'wButtonLike',
    'wTagLikeCount',
    'wCardsCommentsLabels',
    'wCardsLikeLabels',
  ],
  useLTS: true,
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      fetchCollections: false,
      universes: [
        {
          name: 'metier:alimentaire',
          type: 'tag',
          tagId: tagIDs.RTA,
          i18n: {
            fr: {
              title: 'RTA',
              description: 'Votre radio RTA',
            },
            pl: {
              title: 'RTA',
              description: 'Radio RTA',
            },
            pt: {
              title: 'RTA',
              description: 'Rádio RTA',
            },
            en: {
              title: 'RTA',
              description: 'Your radio RTA',
            },
          },
          heading: {
            canRadio: false,
            color: '#d21233',
            dark: true,
            buttonColor: 'red lighten-5',
            hideTitle: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/rta-mobile.png')
                : require('~/assets/images/rta.png')
            },
            thumbnailImage() {
              return require('~/assets/images/logo_rta_white.svg')
            },
          },
          isVisible({ context }) {
            const { state } = context.$store
            const userSegments = $get(state.auth.user, 'protected.segments', [])

            const isForbiddenFromCountry = userSegments.some((segment) => {
              return [
                'pays:pologne',
                'pays:belgique',
                'pays:portugal',
              ].includes(segment.name)
            })

            if (isForbiddenFromCountry === true) {
              return false
            }

            return USER_RTA_COLLECTION.some((name) => {
              return userSegments.map((seg) => seg.name).includes(name)
            })
          },
          filters: {
            type: 'solo',
            soloTagId: tagIDs.RTAFilterTag,
            sort: (category) => {
              const clonedCategory = $clone(category)
              const subTags = clonedCategory.tags
              const displaidTags = []
              displaidTags.push(findTag('le-prix', subTags))
              displaidTags.push(findTag('discount', subTags))
              displaidTags.push(findTag('le-digital', subTags))
              displaidTags.push(findTag('developpement-du-reseau', subTags))
              displaidTags.push(findTag('marche', subTags))
              displaidTags.push(findTag('engagements-r-s-e', subTags))
              displaidTags.push(findTag('contenus-inspirants', subTags))
              // displaidTags.push(findTag('flashs-speciaux', subTags)) //
              displaidTags.push(findTag('flashs-speciaux', subTags))
              displaidTags.push(findTag('emissions', subTags))
              displaidTags.push(findTag('les-essentiels', subTags))

              clonedCategory.tags = displaidTags.filter(Boolean)
              return clonedCategory
            },
          },
        },
        {
          name: 'metier:agromousquetaires',
          type: 'tag',
          tagId: tagIDs.agromousquetaires,
          i18n: {
            fr: {
              title: '50 ans Agromousquetaires',
              description:
                "Collection spéciale : 50 ans d'histoire de notre pôle Agromousquetaires à découvrir en avant-première",
            },
            pl: {
              title: '50 ans Agromousquetaires',
              description:
                "Collection spéciale : 50 ans d'histoire de notre pôle Agromousquetaires à découvrir en avant-première",
            },
            pt: {
              title: '50 ans Agromousquetaires',
              description:
                "Collection spéciale : 50 ans d'histoire de notre pôle Agromousquetaires à découvrir en avant-première",
            },
            en: {
              title: '50 ans Agromousquetaires',
              description:
                "Collection spéciale : 50 ans d'histoire de notre pôle Agromousquetaires à découvrir en avant-première",
            },
          },
          heading: {
            canRadio: false,
            color: '#bcbe07',
            dark: true,
            buttonColor: 'green lighten-5',
            hideTitle: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/rta-mobile.png')
                : require('~/assets/images/rta.png')
            },
            thumbnailImage() {
              return require('~/assets/images/logo_rta_white.svg')
            },
          },
          isVisible({ context }) {
            const { state } = context.$store
            const userSegments = $get(state.auth.user, 'protected.segments', [])

            const isForbiddenFromCountry = userSegments.some((segment) => {
              return [
                'pays:pologne',
                'pays:belgique',
                'pays:portugal',
              ].includes(segment.name)
            })

            if (isForbiddenFromCountry === true) {
              return false
            }

            return USER_RTA_COLLECTION.some((name) => {
              return userSegments.map((seg) => seg.name).includes(name)
            })
          },
        },
        {
          name: 'metier:maison',
          type: 'tag',
          tagId: tagIDs.RTM,
          i18n: {
            fr: {
              title: 'RTM',
              description: 'Votre radio RTM',
            },
            pl: {
              title: 'RTM',
              description: 'Radio RTM',
            },
            pt: {
              title: 'RTM',
              description: 'Rádio RTM',
            },
            en: {
              title: 'RTM',
              description: 'Your radio RTM',
            },
          },
          heading: {
            canRadio: false,
            color: '#FFB200',
            dark: true,
            buttonColor: 'yellow lighten-5',
            hideTitle: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/rtm-mobile.png')
                : require('~/assets/images/rtm.png')
            },
            thumbnailImage() {
              return require('~/assets/images/logo_rtm_white.svg')
            },
          },
          isVisible({ context }) {
            const { state } = context.$store
            const userSegments = $get(state.auth.user, 'protected.segments', [])

            const isForbiddenFromCountry = userSegments.some((segment) => {
              return [
                'pays:pologne',
                'pays:belgique',
                'pays:portugal',
              ].includes(segment.name)
            })

            if (isForbiddenFromCountry === true) {
              return false
            }

            return USER_RTM_COLLECTION.some((name) => {
              return userSegments.map((seg) => seg.name).includes(name)
            })
          },
          filters: {
            type: 'solo',
            soloTagId: tagIDs.RTMFilterTag,
            sort: (category) => {
              const clonedCategory = $clone(category)
              const subTags = clonedCategory.tags
              const displaidTags = []
              displaidTags.push(findTag('info-metiers', subTags))
              displaidTags.push(findTag('r-s-e', subTags))
              displaidTags.push(findTag('temoignages', subTags))
              displaidTags.push(findTag('flashs-speciaux', subTags))
              displaidTags.push(findTag('emissions', subTags))

              clonedCategory.tags = displaidTags.filter(Boolean)
              return clonedCategory
            },
          },
        },
        {
          type: 'tag',
          tagId: tagIDs.RTG,
          i18n: {
            fr: {
              title: 'RTG',
              description: 'Votre radio RTG',
            },
            pl: {
              title: 'RGM',
              description: 'Radia Głos Muszkieterów',
            },
            pt: {
              title: 'RAM',
              description: 'Rádio Aderentes Mosqueteiros',
            },
            en: {
              title: 'AGR',
              description: 'All Groupment Radio',
            },
          },
          isVisible({ context }) {
            const { state } = context.$store
            const userSegments = $get(state.auth.user, 'protected.segments', [])

            return USER_RTG_COLLECTION.some((name) => {
              return userSegments.map((seg) => seg.name).includes(name)
            })
          },
          heading: {
            canRadio: false,
            color: 'grey darken-1',
            dark: true,
            buttonColor: 'grey lighten-5',
            hideTitle: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/rtgmobile.png')
                : require('~/assets/images/rtg.png')
            },
            thumbnailImage() {
              return require('~/assets/images/logo_rtg_white.svg')
            },
          },
        },
      ],
      view: {
        defaultPublisher: {
          name({ cmp, content }) {
            let defaultName = 'Les Ondes M'

            if (content.tags.includes('RTA')) defaultName = 'RTA'
            else if (content.tags.includes('RTG')) defaultName = 'RTG'
            else if (content.tags.includes('RTM')) defaultName = 'RTM'

            return defaultName
          },
        },
        displayPublisher: true,
      },
    },
  ],
})

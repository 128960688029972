/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  ...require('./config.common')({
    agromousquetaires: '65cb4b6b3f03d713a1b51b8b',
    RTA: '601039613cdafe63a495f979',
    RTAFilterTag: '6135d8c4dbefa1144cf3050b',
    RTG: '601039683cdafe081495f97a',
    RTM: '6010395a3cdafeadeb95f978',
    RTMFilterTag: '6135d8c4dbefa1144cf3050b',
  }),
}
